(function () {

	const kv = document.getElementById('kv');
	const header = document.getElementById('header');

	if (!kv || !header) {
		return false;
	}

	const scroll = () => {
		if (kv.getBoundingClientRect().bottom <= 0) {
			if (!header.classList.contains('show')) {
				header.classList.add('show');
			}
		} else {
			if (header.classList.contains('show')) {
				header.classList.remove('show');
			}
		}
	};

	window.addEventListener('load', scroll);
	window.addEventListener('scroll', scroll);

})();